import io from 'socket.io-client';
import { SERVER_URL } from './config';

const socket = io(SERVER_URL, {
    reconnectionDelay: 1000,
    reconnection: true,
    reconnectionAttemps: 10,
    transports: ['websocket'],
    agent: false,
    upgrade: false,
    rejectUnauthorized: false
});

export default socket;
