import React, { useEffect, useState } from 'react';
import '../css/quiz.css';
import '../css/App.css';
import QuestionBox from "./QuestionBox";
import {SERVER_URL} from "../config";
import socket from '../socket';
import { manageTimer } from './quizFunctions';

const Quiz = () => {
    //MUSIK
    const qtimer = `${SERVER_URL}/uploads/sounds/qtimer.mp3`;
    const intermezzo = `${SERVER_URL}/uploads/sounds/intermezzo.mp3`;

    const [question, setQuestion] = useState([]);
    const [countdown, setCountdown] = useState(null);
    const [answerCount, setAnswerCount] = useState(0);
    const [revealFrage, setRevealFrage] = useState(false);
    const [revealAnswer, setRevealAnswer] = useState(false);
    const [showFile, setShowFile] = useState(false);
    const [timer, setTimer] = useState(null);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [aktuellesTeam, setAktuellesTeam] = useState(null);

    useEffect(() => {
        // Event-Listener für das Starten einer Frage
        socket.on('startQuestion', (newQuestion) => {
            setQuestion(newQuestion);
            // Stoppen Sie das aktuell spielende Audio, falls vorhanden
            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }

            // Erstellen Sie ein neues Audio-Objekt und spielen Sie die Datei ab
            const newAudio = new Audio(qtimer);
            newAudio.loop = true; // Setzen Sie die Loop-Eigenschaft auf true
            setCurrentAudio(newAudio); // Setzen Sie das aktuelle Audio-Objekt

            newAudio.volume = 0.5;

            newAudio.play()
                .then(() => console.log("Audio wird abgespielt."))
                .catch(error => console.error("Fehler beim Abspielen der Audio-Datei:", error));
        });

        // Event-Listener für das Starten einer Schätzung
        socket.on('startEstimation', (time) => {
            setCountdown(10); // Setzen Sie den Countdown auf 10 Sekunden

            manageTimer(
                setTimer,
                setCountdown,
                countdown,
                'progress-bar', // ID der Fortschrittsleiste
                null, // ID des Textelements
                () => {
                    const audioUrl = `${SERVER_URL}/uploads/sounds/vote_end.mp3`;
                    const audio = new Audio(audioUrl);
                    audio.volume = 0.75;
                    audio.play().catch(error => console.error("Fehler beim Abspielen der Audio-Datei:", error));
                }
            );
        });

        socket.on('startTimer', (countdown) => {
            setCountdown(300); // 3:20 min

            manageTimer(
                setTimer,
                setCountdown,
                countdown,
                'progress-bar', // ID der Fortschrittsleiste
                null, // ID des Textelements
                () => {

                }
            );
        });

        socket.on('stopTimer', () => {
            clearInterval(timer);
            console.log("Timer stop");
        });

        socket.on('continueTimer', (countdown) => {
            manageTimer(
                setTimer,
                setCountdown,
                countdown,
                'progress-bar', // ID der Fortschrittsleiste
                null, // ID des Textelements
                () => {

                }
            );
        });

        socket.on('showQuestion', (question) => {
            setRevealFrage(true);
        });

        socket.on('showAnswer', (data) => {
            setAnswerCount(data);
            console.log("showAnswer received, answerCount:", data+1);
        });

        socket.on('revealAnswer', (question) => {
            clearInterval(timer);
            setRevealAnswer(true);

            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }
        });

        socket.on('nextQuestion', () => {
            setQuestion([]);
            setAnswerCount(0);
            setRevealFrage(false);
            setRevealAnswer(false);
            setShowFile(false);

            // Stoppen Sie das aktuell spielende Audio, falls vorhanden
            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }

            // Erstellen Sie ein neues Audio-Objekt und spielen Sie die Datei ab
            const newAudio = new Audio(intermezzo);
            newAudio.loop = true; // Setzen Sie die Loop-Eigenschaft auf true
            setCurrentAudio(newAudio); // Setzen Sie das aktuelle Audio-Objekt

            newAudio.volume = 0.05;

            newAudio.play()
                .then(() => console.log("Audio wird abgespielt."))
                .catch(error => console.error("Fehler beim Abspielen der Audio-Datei:", error));

            setCountdown(0);
        });

        // Event-Listener für das Anzeigen einer Datei
        socket.on('showFile', () => {
            setShowFile(true);
        });

        socket.on('aktuellesTeam', (teamId) => {
            setAktuellesTeam(teamId);
        });

        return () => {
            socket.off('startQuestion');
            socket.off('startEstimation');
            socket.off('startTimer');
            socket.off('stopTimer');
            socket.off('continueTimer');
            socket.off('selectQuestion');
            socket.off('showQuestion');
            socket.off('showAnswer');
            socket.off('revealAnswer');
            socket.off('nextQuestion');
            socket.off('showFile');
            socket.off('aktuellesTeam');
        };
    }, [countdown, currentAudio, intermezzo, qtimer, timer]);

    // const istMeinTeamAktiv = meinTeamId === aktuellesTeam;

    return (
        <div className="quiz-container-quiz">
            <div className="head-container-quiz">
                <div className="progress-container-quiz">
                    <div className="progress-bar" id="progress-bar"></div>
                </div>
            </div>
            <main className="mainDASH">
                {question && (
                    <QuestionBox
                        question={question}
                        answerCount={answerCount}
                        revealFrage={revealFrage}
                        revealAnswer={revealAnswer}
                        showFile={showFile}
                    />
                )}
            </main>
            <div className="players-container">
                {/* Hier werden die iframes für die Spieler angezeigt */}
            </div>
        </div>
    );
};

export default Quiz;