// VideoModal.js
import React from 'react';
import '../css/modal.css';

const VideoModal = ({ src, onClose }) => {
    return (
        <div className="modal-background" onClick={onClose}>
            <video controls className="modal-image" onClick={(e) => e.stopPropagation()}>
                <source src={src} type="video/mp4" />
                Ihr Browser unterstützt das Video-Tag nicht.
            </video>
        </div>
    );
};

export default VideoModal;