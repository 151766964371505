// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Stile für die Sidebar */
.sidebar {
    padding: 20px;
}

/* Stile für die Navigationslinks */
.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar li {
    margin-bottom: 20px;
}

.sidebar a {
    text-decoration: none;
    color: #333;
    font-size: 24px;
}

.sidebar a:hover {
    color: mediumslateblue;
}`, "",{"version":3,"sources":["webpack://./src/css/sidebar.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,aAAa;AACjB;;AAEA,mCAAmC;AACnC;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["/* Stile für die Sidebar */\n.sidebar {\n    padding: 20px;\n}\n\n/* Stile für die Navigationslinks */\n.sidebar ul {\n    list-style: none;\n    padding: 0;\n}\n\n.sidebar li {\n    margin-bottom: 20px;\n}\n\n.sidebar a {\n    text-decoration: none;\n    color: #333;\n    font-size: 24px;\n}\n\n.sidebar a:hover {\n    color: mediumslateblue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
