import React from "react";

const QuestionBox = ({ question, answerCount, revealFrage, revealAnswer, showFile }) => {
    const isCorrectAnswer = (answerLetter) => {
        if (revealAnswer) {
            return answerLetter === question.richtigeAntwort;
        }
        return false;
    };

    return (
        <div className="question-section">
            {revealFrage && (
                <div className="question-container">
                    <div className="question-text">
                        {question.frage}
                    </div>
                </div>
            )}

            {answerCount > 0 && (
                <div className={`answer-container ${isCorrectAnswer('A') ? 'correct-answer' : ''}`}>
                    <span className="answer-letter">A</span><span className="answer-text">{question.antwortA}</span>
                </div>
            )}

            {answerCount > 1 && (
                <div className={`answer-container ${isCorrectAnswer('B') ? 'correct-answer' : ''}`}>
                    <span className="answer-letter">B</span><span className="answer-text">{question.antwortB}</span>
                </div>
            )}

            {answerCount > 2 && (
                <div className={`answer-container ${isCorrectAnswer('C') ? 'correct-answer' : ''}`}>
                    <span className="answer-letter">C</span><span className="answer-text">{question.antwortC}</span>
                </div>
            )}

            {answerCount > 3 && (
                <div className={`answer-container aD ${isCorrectAnswer('D') ? 'correct-answer' : ''}`}>
                    <span className="answer-letter">D</span><span className="answer-text">{question.antwortD}</span>
                </div>
            )}

            {showFile && question.dateiPath && (
                <img src={question.dateiPath} alt="Fragebild" className="question-image" />
            )}
        </div>
    );
};

export default QuestionBox;