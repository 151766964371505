import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import Fragen from './components/dashboard/Fragen';
import Spieler from './components/dashboard/Spieler';
import Quiz from "./components/Quiz";
import './css/App.css';
import { SERVER_URL } from "./config";
import socket from './socket'; // Importieren der Socket-Instanz

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Anmeldeseite />} />
            <Route path="/quiz" element={<Quiz />} />
          <Route path="dashboard/*" element={<Dashboard />} />
            <Route path="dashboard/fragen" element={<Fragen />} />
            <Route path="dashboard/spieler" element={<Spieler />} />
        </Routes>
      </Router>
  );
}

const Anmeldeseite = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [link, setLink] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${SERVER_URL}/authenticate`;
      try {
          const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ username, password, link }),
          });

      if (response.status === 200) {
        const data = await response.json();
        localStorage.setItem('token', data.token);

        const username = data.username;
        socket.emit('saveUserId', username);
        console.log(username, ' übertragen für Online-Status');

        const base64Url = data.token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const payload = JSON.parse(window.atob(base64));
        if (payload.role === 'admin') {
          window.location.href = '/dashboard';
        } else {
          window.location.href = '/quiz';
        }
      } else {
        alert('Anmeldung fehlgeschlagen!');
      }
    } catch (error) {
      console.error('Fehler bei der Anmeldung:', error);
    }
  };

  return (
      <div style={{ color: "whitesmoke", maxWidth: '300px', margin: '0 auto' }}>
        <form onSubmit={handleSubmit}>
          <h2>Anmeldeseite</h2>
          <label>
            Benutzername:
            <br />
            <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
            />
          </label>
          <br /><br />
          <label>
            Passwort (optional):
            <br/>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <br /><br />
            <label>
                Link:
                <br />
                <input
                    type="url"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="https://example.com"
                />
            </label>
            <br /><br />
          <button type="submit">Anmelden</button>
        </form>
      </div>
  );
};

export default App;