// Fragen.js
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import '../../css/fragen.css';
import ImageModal from '../ImageModal';
import VideoModal from '../VideoModal';
import { SERVER_URL } from '../../config';

const socket = io(process.env.SERVER_URL);

const Fragen = () => {
    const [frage, setFrage] = useState('');
    const [antwortA, setAntwortA] = useState('');
    const [antwortB, setAntwortB] = useState('');
    const [antwortC, setAntwortC] = useState('');
    const [antwortD, setAntwortD] = useState('');
    const [richtigeAntwort, setRichtigeAntwort] = useState('');
    const [datei, setDatei] = useState(null);

    const [fragenListe, setFragenListe] = useState([]);

    const [activeImage, setActiveImage] = useState(null);
    const [activeVideo, setActiveVideo] = useState(null);


    useEffect(() => {
        // Fragen von der Datenbank holen, wenn die Komponente geladen wird
        fetch(`${SERVER_URL}/get-questions`)
            .then(response => response.json())
            .then(data => setFragenListe(data))
            .catch(error => console.error(error));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Erstellen eines FormData-Objekts, um die Dateien und Texte als Multi-Part-Formulardaten zu senden.
        const formData = new FormData();
        formData.append('frage', frage);
        formData.append('antwortA', antwortA);
        formData.append('antwortB', antwortB);
        formData.append('antwortC', antwortC);
        formData.append('antwortD', antwortD);
        formData.append('richtigeAntwort', richtigeAntwort);
        formData.append('datei', datei);

        try {
            const response = await fetch(`${SERVER_URL}/add-question`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Netzwerkantwort war nicht ok');
            }

            const data = await response.json();

            if (data.success) {
                setFrage('');
                setAntwortA('');
                setAntwortB('');
                setAntwortC('');
                setAntwortD('');
                setRichtigeAntwort('');
                setDatei(null);

                // Die neue Frage zur Fragenliste hinzufügen
                setFragenListe([...fragenListe, data.data]);

            } else {
                console.error("Eintrag war nicht erfolgreich:", data.error);
                console.log("Serverantwort:", data);
            }
        } catch (error) {
            console.error("Es gab einen Fehler beim Senden des Formulars:", error);
        }
    };

    const handleDelete = async (id) => {
        // Frage löschen
        await fetch(`${SERVER_URL}/delete-question/${id}`, {
            method: 'DELETE'
        });

        // Fragen-Liste aktualisieren
        setFragenListe(fragenListe.filter(frage => frage.id !== id));
    };

    return (
        <>
        <div className="container-frage">
            <nav><Navbar /></nav>
            <div className="sidebar"><Sidebar /></div>
            <div id="content1-frage"><h2>Neue Frage</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Frage:
                        <input type="text" value={frage} onChange={(e) => setFrage(e.target.value)} required />
                    </label><br/><br/>
                    <label>
                        Antwort A:
                        <input type="text" value={antwortA} onChange={(e) => setAntwortA(e.target.value)} required />
                    </label><br/><br/>
                    <label>
                        Antwort B:
                        <input type="text" value={antwortB} onChange={(e) => setAntwortB(e.target.value)} required />
                    </label><br/><br/>
                    <label>
                        Antwort C:
                        <input type="text" value={antwortC} onChange={(e) => setAntwortC(e.target.value)} required />
                    </label><br/><br/>
                    <label>
                        Antwort D:
                        <input type="text" value={antwortD} onChange={(e) => setAntwortD(e.target.value)} required />
                    </label><br/><br/>
                    <label>
                        Richtige Antwort:
                        <select onChange={(e) => setRichtigeAntwort(e.target.value)} required>
                            <option value="">Wählen Sie die richtige Antwort</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                        </select>
                    </label><br/><br/>
                    <label>
                        Datei anhängen:
                        <input type="file" onChange={(e) => setDatei(e.target.files[0])} />
                    </label><br/><br/>
                    <button type="submit">Frage hinzufügen</button>
                </form>
            </div>
            <div id="content2">

            </div>
            <div id="content3">

            </div>
            <main className="fragen-uebersicht">
                {fragenListe.map(frage => (
                    <div key={frage.id} className="frage-container">
                        <div className="fragebe">{frage.frage}</div>
                        <div className="antworten">
                            <div className={frage.richtigeAntwort === 'A' ? 'richtige-antwort' : ''}>A: {frage.antwortA}</div>
                            <div className={frage.richtigeAntwort === 'B' ? 'richtige-antwort' : ''}>B: {frage.antwortB}</div>
                            <div className={frage.richtigeAntwort === 'C' ? 'richtige-antwort' : ''}>C: {frage.antwortC}</div>
                            <div className={frage.richtigeAntwort === 'D' ? 'richtige-antwort' : ''}>D: {frage.antwortD}</div>
                            <div className="medien-container">
                                {(() => {
                                    console.log("Datei:", frage.dateiPath); // Dies loggt den Wert von 'frage.datei'
                                    return null; // Dies sorgt dafür, dass nichts in den JSX gerendert wird
                                })()}
                                {frage.dateiPath && (frage.dateiPath.endsWith('.jpg') || frage.dateiPath.endsWith('.jpeg') || frage.dateiPath.endsWith('.png')) &&
                                    <img
                                        src={`${SERVER_URL}/${frage.dateiPath.replace(/\\/g, '/')}`}
                                        alt="Bildbeschreibung"
                                        onClick={() => setActiveImage(frage.dateiPath)}
                                    />

                                }
                                {frage.dateiPath && frage.dateiPath.endsWith('.mp4') &&
                                    <button onClick={() => setActiveVideo(frage.dateiPath)}>
                                        Video abspielen
                                    </button>
                                }
                                {frage.dateiPath && frage.dateiPath.endsWith('.mp3') &&
                                    <audio controls>
                                            <source src={`${SERVER_URL}/${frage.dateiPath.replace(/\\/g, '/')}`} type="audio/mpeg" />
                                    </audio>
                                }
                            </div>
                        </div>
                        <button className="loeschen-btn" onClick={() => handleDelete(frage.id)}>Löschen</button>
                    </div>
                ))}
            </main>
        </div>
            {activeImage &&
                <ImageModal
                    src={`${SERVER_URL}/${activeImage.replace(/\\/g, '/')}`}
                    alt="Bildbeschreibung"
                    onClose={() => setActiveImage(null)}
                />
            }
            {activeVideo &&
                <VideoModal
                    src={`${SERVER_URL}/${activeVideo.replace(/\\/g, '/')}`}
                    onClose={() => setActiveVideo(null)}
                />
            }
        </>
    );
};

export default Fragen;
