// quizFunctions.js
export const manageTimer = (setTimer, setCountdown, countdown, progressBarId, progressTextId, onEnd) => {
    let timer = setInterval(() => {
        setCountdown((prevCountdown) => {
            const remainingTime = prevCountdown - 1;
            const percent = (remainingTime / countdown) * 100;

            // Update the progress bar and text
            const progressBar = document.getElementById(progressBarId);
            progressBar.style.width = `${percent}%`;

            if (progressTextId) {
                const progressText = document.getElementById(progressTextId);
                progressText.innerText = remainingTime.toString();
            }

            if (remainingTime <= 0) {
                clearInterval(timer); // Timer stoppen
                onEnd(); // Funktion am Ende des Timers aufrufen
                return 0;
            }

            return remainingTime;
        });
    }, 1000);
    setTimer(timer); // Timer-ID speichern
};

export const handleAudio = (audioUrl, currentAudio, setCurrentAudio, volume) => {
    // Stoppen Sie das aktuell spielende Audio, falls vorhanden
    if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
    }

    // Erstellen Sie ein neues Audio-Objekt und spielen Sie die Datei ab
    const newAudio = new Audio(audioUrl);
    newAudio.loop = true; // Setzen Sie die Loop-Eigenschaft auf true
    setCurrentAudio(newAudio); // Setzen Sie das aktuelle Audio-Objekt
    newAudio.volume = volume;

    newAudio.play()
        .then(() => console.log(currentAudio, " wird abgespielt."))
        .catch(error => console.error("Fehler beim Abspielen der Audio-Datei:", error));
};
