import React from 'react';
import '../css/modal.css';

const ImageModal = ({ src, alt, onClose }) => {
    return (
        <div className="modal-background" onClick={onClose}>
            <div className="image-modal" onClick={(e) => e.stopPropagation()}> {/* Dies verhindert das Schließen beim Klicken auf das Bild */}
                <button onClick={onClose}>Schließen</button>
                <img className="modal-image" src={src} alt={alt} />
            </div>
        </div>
    );
};

export default ImageModal;
