// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-container-quiz {
    margin-top: 10px;
    margin-bottom: 20px;
}

.progress-container-quiz {
    position: relative;
    width: 100%;
    height: 25px; /* Oder die Höhe, die Sie bevorzugen */
    background-color: #e0e0e0;
    border-radius: 25px; /* Halbe Höhe für vollständig gerundete Ecken */
    overflow: hidden; /* Stellt sicher, dass der Innenbalken innerhalb des Containers bleibt */
}

.progress-bar {
    position: absolute;
    width: 100%; /* Startbreite, wird dynamisch aktualisiert */
    height: 100%;
    background-color: #76c7c0; /* Oder jede Farbe, die Sie bevorzugen */
}

.progress-text {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 50px; /* Sollte der Höhe des Containers entsprechen */
    font-size: 20px;
    color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/css/quiz.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY,EAAE,sCAAsC;IACpD,yBAAyB;IACzB,mBAAmB,EAAE,+CAA+C;IACpE,gBAAgB,EAAE,wEAAwE;AAC9F;;AAEA;IACI,kBAAkB;IAClB,WAAW,EAAE,6CAA6C;IAC1D,YAAY;IACZ,yBAAyB,EAAE,wCAAwC;AACvE;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB,EAAE,+CAA+C;IAClE,eAAe;IACf,cAAc;AAClB","sourcesContent":[".head-container-quiz {\n    margin-top: 10px;\n    margin-bottom: 20px;\n}\n\n.progress-container-quiz {\n    position: relative;\n    width: 100%;\n    height: 25px; /* Oder die Höhe, die Sie bevorzugen */\n    background-color: #e0e0e0;\n    border-radius: 25px; /* Halbe Höhe für vollständig gerundete Ecken */\n    overflow: hidden; /* Stellt sicher, dass der Innenbalken innerhalb des Containers bleibt */\n}\n\n.progress-bar {\n    position: absolute;\n    width: 100%; /* Startbreite, wird dynamisch aktualisiert */\n    height: 100%;\n    background-color: #76c7c0; /* Oder jede Farbe, die Sie bevorzugen */\n}\n\n.progress-text {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    text-align: center;\n    line-height: 50px; /* Sollte der Höhe des Containers entsprechen */\n    font-size: 20px;\n    color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
