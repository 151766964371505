// Spieler.js
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import '../../css/spieler.css';
import { SERVER_URL } from '../../config';

const socket = io(process.env.SERVER_URL);

const Spieler = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [team, setTeam] = useState('');
    const [link, setLink] = useState('');
    const [users, setUsers] = useState([]);
    const [isUserOnline, setIsUserOnline] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    // Function to fetch users
    const fetchUsers = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/users`);
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setUsername(user.username);
        setPassword(''); // Consider handling the password more securely
        setLink(user.link);
        setIsAdmin(user.isAdmin);
        setTeam(user.team);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userData = {
            username,
            link,
            isAdmin,
            team
        };

        // Fügen Sie das Passwort nur hinzu, wenn es nicht leer ist
        if (password) {
            userData.password = password;
        }

        try {
            const response = await fetch(`${SERVER_URL}/users/${selectedUser ? selectedUser.id : ''}`, {
                method: selectedUser ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();

            if (data.success) {
                fetchUsers(); // Refresh the user list after a successful addition/update
                setUsername('');
                setPassword('');
                setIsAdmin(false);
                setTeam('');
                setLink('');
            } else {
                console.error("An error occurred while submitting the form: ", data.error);
            }
        } catch (error) {
            console.error("An error occurred while submitting the form: ", error);
        }
    }

    const handleDeleteUser = async (userId) => {
        try {
            const response = await fetch(`${SERVER_URL}/users/${userId}`, { method: 'DELETE' });
            if (response.ok) {
                fetchUsers(); // Refresh the user list after a successful delete
            }
        } catch (error) {
            console.error("An error occurred while deleting the user:", error);
        }
    };

    const handleUserLogout = (userId) => {
        return async () => {
            try {
                const response = await fetch(`${SERVER_URL}/users/logout/${userId}`, {
                    method: 'POST',
                });

                if (response.ok) {
                    fetchUsers(); // Die Benutzerliste aktualisieren
                } else {
                    console.error("Fehler beim Abmelden des Benutzers");
                }
            } catch (error) {
                console.error("Fehler beim Abmelden des Benutzers: ", error);
            }
        };
    };

    return (
        <div className="container-spieler">
            <nav><Navbar /></nav>
            <div className="sidebar"><Sidebar /></div>
            <div id="content1-spieler">
                <h2>Benutzer</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Benutzername:
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    </label><br/><br/>
                    <label>
                        Passwort:
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label><br/><br/>
                    <label>
                        Ist Admin:
                        <input type="checkbox" checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />
                    </label><br/><br/>
                    <label>
                        Team:
                        <input type="text" value={team} onChange={(e) => setTeam(e.target.value)} />
                    </label><br/><br/>
                    <label>
                        Link:
                        <input type="url" value={link} onChange={(e) => setLink(e.target.value)} />
                    </label><br/><br/>
                    <button type="submit">Benutzer hinzufügen/aktualisieren</button>
                </form>
            </div>
            <div id="content2-spieler">

            </div>
            <div id="content3-spieler">

            </div>
            <main className="spieler-uebersicht">
                {users.map(user => (
                    <div key={user.id} className="user-container">
                        <div className="user-info">
                            <div className="username">{user.username}</div>
                            <div className="userdaten">
                                <div className="password">Passwort: {user.password}</div>
                                <div className="isAdmin">Admin: {user.isAdmin ? 'Ja' : 'Nein'}</div>
                                <div className="team">Team: {user.team ? user.team : '-'}</div>
                            </div>
                        </div>

                        {user.link &&
                            <div className="iframe-container">
                                <iframe
                                    src={user.link}
                                    title={`Vorschau von ${user.username}'s Link`}
                                    width="600"
                                    height="400"
                                    sandbox="allow-same-origin allow-scripts"
                                    scrolling="no">
                                </iframe>
                            </div>
                        }

                        <div className="buttons">
                            <button className="btn edit-btn" onClick={() => handleSelectUser(user)}>Bearbeiten</button>
                            <button className="btn delete-btn" onClick={() => handleDeleteUser(user.id)}>Löschen</button>
                            {isUserOnline && (
                                <button className="btn logout-btn" onClick={handleUserLogout(user.id)}>Abmelden</button>
                            )}
                        </div>
                    </div>
                ))}
            </main>
        </div>
    );
};

export default Spieler;
