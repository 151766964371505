// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Ein semi-transparenter Hintergrund */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Dies stellt sicher, dass der Hintergrund über anderen Elementen angezeigt wird */
}

.modal-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/css/modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,uCAAuC;IAC7E,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,mFAAmF;AACtG;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".modal-background {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7); /* Ein semi-transparenter Hintergrund */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000; /* Dies stellt sicher, dass der Hintergrund über anderen Elementen angezeigt wird */\n}\n\n.modal-image {\n    max-width: 100%;\n    max-height: 100%;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
