import React, { useEffect, useState } from 'react';
import '../../css/App.css';
import '../../css/dashboard.css';
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import QuestionBox from "../QuestionBox";
import { Outlet } from "react-router-dom";
import { SERVER_URL } from "../../config";
import socket from '../../socket'; // Importieren der Socket-Instanz
import { manageTimer, handleAudio } from '../quizFunctions';

    const Dashboard = () => {
        const [fragenListe, setFragenListe] = useState([]);
        const [question, setQuestion] = useState([]);
        const [countdown, setCountdown] = useState(null);
        const [answerCount, setAnswerCount] = useState(0);
        const [revealFrage, setRevealFrage] = useState(false);
        const [revealAnswer, setRevealAnswer] = useState(false);
        const [showFile, setShowFile] = useState(false);
        const [timer, setTimer] = useState(null);
        const [currentAudio, setCurrentAudio] = useState(null);
        const [teams, setTeams] = useState([]); // Speichern der Teamdaten

        //MUSIK
        const qtimer = `${SERVER_URL}/uploads/sounds/qtimer.mp3`;
        const intermezzo = `${SERVER_URL}/uploads/sounds/intermezzo.mp3`;

        useEffect(() => {
            // Fragen von der Datenbank holen, wenn die Komponente geladen wird
            fetch(`${SERVER_URL}/get-questions`)
                .then(response => response.json())
                .then(data => setFragenListe(data))
                .catch(error => console.error(error));

        }, []); // Keine Abhängigkeiten, der Effekt wird nur beim Mounten und Unmounten der Komponente ausgeführt

        //CONTENT 1


        // CONTENT 2
        const handleStartQuestion = () => {
            socket.emit('startQuestion', question);
            handleAudio(qtimer, currentAudio, setCurrentAudio, 0.5);
            console.log(`Frage gestartet: ${question.frage}`);
        };

        const handleEstimate = () => {
            clearInterval(timer);
            setCountdown(10); // Setzen Sie den Countdown auf 10 Sekunden
            socket.emit('startEstimation', countdown);

            manageTimer(
                setTimer,
                setCountdown,
                countdown,
                'progress-bar', // ID der Fortschrittsleiste
                'progress-text', // ID des Textelements
                () => {
                    const audioUrl = `${SERVER_URL}/uploads/sounds/vote_end.mp3`;
                    const audio = new Audio(audioUrl);
                    audio.volume = 0.75;
                    audio.play().catch(error => console.error("Fehler beim Abspielen der Audio-Datei:", error));
                }
            );
        };

        const handleTimer = () => {
            clearInterval(timer);
            setCountdown(300); // 3:20 min
            socket.emit('startTimer', countdown);

            manageTimer(
                setTimer,
                setCountdown,
                countdown,
                'progress-bar', // ID der Fortschrittsleiste
                'progress-text', // ID des Textelements
                () => {

                }
            );
        };

        const handleStopTimer = () => {
            // Timer anhalten
            socket.emit('stopTimer');
            clearInterval(timer);
            console.log("Timer stop");
        };

        const handleContinueTimer = () => {
            // Timer fortsetzen
            socket.emit('continueTimer', countdown);

            manageTimer(
                setTimer,
                setCountdown,
                countdown,
                'progress-bar', // ID der Fortschrittsleiste
                'progress-text', // ID des Textelements
                () => {
                    // Logik, die am Ende des Timers ausgeführt wird
                }
            );
        };

        //CONTENT 3
        const handleSelectQuestion = (frage) => {
            setQuestion(frage); // Setzen der ausgewählten Frage
            console.log(`Frage ausgewählt: ${question.frage}`);
        };

        const handleShowQuestion = () => {
            socket.emit('showQuestion', question);
            setRevealFrage(true);
        };

        const handleShowAnswer = () => {
            if (answerCount < 4) {
                const newAnswerCount = answerCount + 1;
                setAnswerCount(newAnswerCount);
                socket.emit('showAnswer', newAnswerCount);
            }
        };

        const handleRevealAnswer = () => {
            socket.emit('revealAnswer', question);
            clearInterval(timer);
            setRevealAnswer(true);

            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }
        };

        const handleNextQuestion = () => {
            socket.emit('nextQuestion');

            setQuestion([]);
            setAnswerCount(0);
            setRevealFrage(false);
            setRevealAnswer(false);
            setShowFile(false);

            handleAudio(intermezzo, currentAudio, setCurrentAudio, 0.05)

            setCountdown(0);
        };

        const handleShowFile = () => {
            socket.emit('showFile', question);
            setShowFile(true);
        };

    return (
            <div className="container">
                <nav><Navbar /></nav>
                <div className="sidebar">
                    <Sidebar />
                    <Outlet />
                </div>
                <main className="mainDASH">
                    {question && (
                    <QuestionBox
                        question={question}
                        answerCount={answerCount}
                        revealFrage={revealFrage}
                        revealAnswer={revealAnswer}
                        showFile={showFile}
                    />
                )}
                </main>
                <div id="fragenübersichtDASH">
                    {fragenListe.map(frage => (
                        <div
                            key={frage.id}
                            className={`frage-containerDASH ${question && frage.id === question.id ? 'selected-question' : ''}`}
                            onClick={() => handleSelectQuestion(frage)}>
                            <div className="frageDASH">{frage.frage}</div>
                        </div>
                    ))}
                </div>
                <div id="content1"></div>
                <div id="content2">
                    <button className="btn btn-startq btn-qshow"onClick={handleStartQuestion}>Frage starten</button>
                    <div className="groupbtn">
                        <button className="btn btn-setvotetimer" onClick={handleEstimate}>Einschätzen</button>
                        <button className="btn btn-settimer" onClick={handleTimer}>Raten</button>
                    </div>
                    <div className="progress-container">
                        <div className="progress-bar" id="progress-bar"></div>
                        <div className="progress-text" id="progress-text">10</div>
                    </div>
                    <button className="btn btn-stoptimer" onClick={handleStopTimer}>Timer Stop</button>
                    <button className="btn btn-conttimer" onClick={handleContinueTimer}>Timer weiter</button>
                </div>
                <div id="content3">
                    <button className="btn btn-qshow" onClick={handleShowQuestion}>Frage anzeigen</button>
                    <button className="btn btn-ashow" onClick={handleShowAnswer}>Antwort anzeigen</button>
                    <button className="btn btn-auflösen" onClick={handleRevealAnswer}>Auflösen</button>
                    <button className="btn btn-next" onClick={handleNextQuestion}>Nächste Frage</button>
                    <button className="btn btn-file" onClick={handleShowFile}>Datei anzeigen</button>
                </div>
                <footer>

                </footer>
            </div>
        );
    };

    export default Dashboard;